"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SampleManualDonorDetails = exports.SampleAvailableAppointment = exports.SampleBookedAppointment = void 0;
const types_1 = require("../types");
exports.SampleBookedAppointment = {
    id: "bookedAppointment",
    booked: true,
    firstName: "משה",
    lastName: "כהן",
    fullName: "משה כהן",
    donationStartTimeMillis: 1702198800000,
    bookingTimeMillis: 1702036800000,
    donorId: "donorId",
    status: types_1.AppointmentStatus.BOOKED,
    hospital: types_1.Hospital.ASAF_HAROFE,
    phone: "0521234567",
    bloodType: types_1.BloodType.A_PLUS,
};
exports.SampleAvailableAppointment = {
    id: "availableAppointment",
    booked: false,
    donationStartTimeMillis: 1702382400000,
    status: types_1.AppointmentStatus.AVAILABLE,
    hospital: types_1.Hospital.TEL_HASHOMER,
};
exports.SampleManualDonorDetails = {
    firstName: "manualFirstName",
    lastName: "manualLastName",
    bloodType: types_1.BloodType.B_PLUS,
    phoneNumber: "0549876543",
};
