"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHospitalOptions = exports.getAllHospitalOptions = exports.ALL_HOSPITALS_SELECT = exports.activeHospitals = void 0;
const types_1 = require("./types");
const index_1 = require("./index");
exports.activeHospitals = [
    types_1.Hospital.ICHILOV,
    types_1.Hospital.BEILINSON,
    types_1.Hospital.HADASA_EIN_KEREM,
    types_1.Hospital.SOROKA,
    types_1.Hospital.TEL_HASHOMER,
];
exports.ALL_HOSPITALS_SELECT = "ALL";
function getAllHospitalOptions(hospitals) {
    const options = getHospitalOptions(hospitals, "בחר");
    if (hospitals.length > 1) {
        options.push({ label: "הכל", key: "הכל", value: exports.ALL_HOSPITALS_SELECT });
    }
    return options;
}
exports.getAllHospitalOptions = getAllHospitalOptions;
// If defaultLabel, will add an option with value ""
function getHospitalOptions(hospitals, defaultLabel) {
    const options = hospitals.map(hospitalToOption);
    if (defaultLabel) {
        options.unshift({ label: defaultLabel, key: defaultLabel, value: "" });
    }
    return options;
}
exports.getHospitalOptions = getHospitalOptions;
function hospitalToOption(hospital) {
    return {
        label: index_1.LocaleUtils.getHospitalName(hospital),
        value: hospital,
        key: hospital,
    };
}
