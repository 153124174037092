"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatePhone = exports.ValidateLastName = exports.ValidateFirstName = exports.PersonalDetailsValidation = void 0;
var PersonalDetailsValidation;
(function (PersonalDetailsValidation) {
    PersonalDetailsValidation["VALID_FIELD"] = "VALID_FIELD";
    PersonalDetailsValidation["NAME_TOO_SHORT"] = "NAME_TOO_SHORT";
    PersonalDetailsValidation["FULL_NAME_TOO_LONG"] = "FULL_NAME_TOO_LONG";
    PersonalDetailsValidation["PHONE_INVALID"] = "PHONE_INVALID";
    PersonalDetailsValidation["PHONE_HAS_NANS"] = "PHONE_HAS_NANS";
    PersonalDetailsValidation["REQUIRED_FIELD"] = "REQUIRED_FIELD";
})(PersonalDetailsValidation = exports.PersonalDetailsValidation || (exports.PersonalDetailsValidation = {}));
const ValidateFirstName = (firstName) => {
    if (!firstName)
        return PersonalDetailsValidation.REQUIRED_FIELD;
    if (firstName.length < 2)
        return PersonalDetailsValidation.NAME_TOO_SHORT;
    return PersonalDetailsValidation.VALID_FIELD;
};
exports.ValidateFirstName = ValidateFirstName;
const ValidateLastName = (lastName, firstName) => {
    if (!lastName)
        return PersonalDetailsValidation.REQUIRED_FIELD;
    if (lastName.length < 2)
        return PersonalDetailsValidation.NAME_TOO_SHORT;
    if (lastName.length + firstName.length > 20)
        return PersonalDetailsValidation.FULL_NAME_TOO_LONG;
    return PersonalDetailsValidation.VALID_FIELD;
};
exports.ValidateLastName = ValidateLastName;
const ValidatePhone = (phone) => {
    if (!phone)
        return PersonalDetailsValidation.REQUIRED_FIELD;
    const allNumbersValidator = /^[0-9]*$/;
    if (!allNumbersValidator.test(phone)) {
        return PersonalDetailsValidation.PHONE_HAS_NANS;
    }
    const formatValidator = /^05(?!6)\d{8}$/;
    if (phone.length > 0 && !formatValidator.test(phone)) {
        return PersonalDetailsValidation.PHONE_INVALID;
    }
    return PersonalDetailsValidation.VALID_FIELD;
};
exports.ValidatePhone = ValidatePhone;
