"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidationErrorTranslation = exports.getStatusTranslation = exports.getBloodTypeTranslation = exports.getHospitalName = void 0;
const types_1 = require("./types");
const Validation_1 = require("./Validation");
function getHospitalName(hospital) {
    switch (hospital) {
        case types_1.Hospital.TEL_HASHOMER:
            return "תל השומר";
        case types_1.Hospital.ASAF_HAROFE:
            return "אסף הרופא";
        case types_1.Hospital.BEILINSON:
            return "בילינסון";
        case types_1.Hospital.ICHILOV:
            return "איכילוב";
        case types_1.Hospital.RAMBAM:
            return 'רמב"ם';
        case types_1.Hospital.SOROKA:
            return "סורוקה";
        case types_1.Hospital.HADASA_EIN_KEREM:
            return "הדסה עין כרם";
        default:
            console.error("No hospital name for", hospital);
            return hospital;
    }
}
exports.getHospitalName = getHospitalName;
const getBloodTypeTranslation = (bloodType) => {
    switch (bloodType) {
        case types_1.BloodType.AB_MINUS:
            return "AB-";
        case types_1.BloodType.AB_PLUS:
            return "AB+";
        case types_1.BloodType.A_MINUS:
            return "A-";
        case types_1.BloodType.A_PLUS:
            return "A+";
        case types_1.BloodType.B_MINUS:
            return "B-";
        case types_1.BloodType.B_PLUS:
            return "B+";
        case types_1.BloodType.O_MINUS:
            return "O-";
        case types_1.BloodType.O_PLUS:
            return "O+";
        case types_1.BloodType.NOT_SURE:
            return "לא ידוע";
        default:
            console.error("No blood type name for", bloodType);
            return bloodType;
    }
};
exports.getBloodTypeTranslation = getBloodTypeTranslation;
const getStatusTranslation = (status, startTimeMillis) => {
    const isPastAppointment = startTimeMillis < Date.now();
    switch (status) {
        case types_1.AppointmentStatus.AVAILABLE:
            return "פנוי";
        case types_1.AppointmentStatus.BOOKED:
            return isPastAppointment ? "אין מידע" : "מוזמן";
        case types_1.AppointmentStatus.COMPLETED:
            return "הושלם";
        case types_1.AppointmentStatus.CONFIRMED:
            return "אישר הגעה";
        case types_1.AppointmentStatus.NOSHOW:
            return "לא הגיע";
        default:
            console.error("No status translation for ", status);
            return status;
    }
};
exports.getStatusTranslation = getStatusTranslation;
const getValidationErrorTranslation = (errorType) => {
    switch (errorType) {
        case Validation_1.PersonalDetailsValidation.NAME_TOO_SHORT:
            return "שם אינו תקין";
        case Validation_1.PersonalDetailsValidation.FULL_NAME_TOO_LONG:
            return "השם המלא ארוך מ-20 תווים";
        case Validation_1.PersonalDetailsValidation.PHONE_INVALID:
            return "מספר הטלפון אינו תקין";
        case Validation_1.PersonalDetailsValidation.PHONE_HAS_NANS:
            return "יש להזין ספרות בלבד";
        case Validation_1.PersonalDetailsValidation.REQUIRED_FIELD:
            return "שדה חובה";
        case Validation_1.PersonalDetailsValidation.VALID_FIELD:
            return "";
        default:
            return "השדה אינו תקין";
    }
};
exports.getValidationErrorTranslation = getValidationErrorTranslation;
