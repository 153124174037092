"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBookedDonationsInHospitalFunctionName = exports.GetDonorsFunctionName = exports.GetCoordinatorAppointmentsFunctionName = exports.DeleteAppointmentsFunctionName = exports.AddNewAppointmentsFunctionName = exports.GetCoordinatorFunctionName = exports.SaveDonorFunctionName = exports.GetDonorFunctionName = exports.CompleteAppointmentFunctionName = exports.CancelAppointmentFunctionName = exports.DonorSwapAppointmentFunctionName = exports.BookAppointmentStatus = exports.DonorBookAppointmentFunctionName = exports.CoordinatorBookAppointmentFunctionName = exports.GetDonorAppointmentsFunctionName = exports.GetAvailableAppointmentsFunctionName = void 0;
// Donor functions:
exports.GetAvailableAppointmentsFunctionName = "getAvailableAppointments";
exports.GetDonorAppointmentsFunctionName = "getDonorAppointments";
exports.CoordinatorBookAppointmentFunctionName = "coordinatorBookAppointment";
exports.DonorBookAppointmentFunctionName = "bookAppointment";
var BookAppointmentStatus;
(function (BookAppointmentStatus) {
    BookAppointmentStatus["SUCCESS"] = "SUCCESS";
    BookAppointmentStatus["NO_AVAILABLE_APPOINTMENTS"] = "NO_AVAILABLE_APPOINTMENTS";
    BookAppointmentStatus["HAS_OTHER_DONATION_IN_BUFFER"] = "HAS_OTHER_DONATION_IN_BUFFER";
    BookAppointmentStatus["DONOR_DETAILS_REQUIRED"] = "DONOR_DETAILS_REQUIRED";
})(BookAppointmentStatus = exports.BookAppointmentStatus || (exports.BookAppointmentStatus = {}));
exports.DonorSwapAppointmentFunctionName = "swapAppointment";
exports.CancelAppointmentFunctionName = "cancelAppointment";
exports.CompleteAppointmentFunctionName = "completeAppointment";
exports.GetDonorFunctionName = "getDonor";
exports.SaveDonorFunctionName = "saveDonor";
// Coordinator functions:
exports.GetCoordinatorFunctionName = "getCoordinator";
exports.AddNewAppointmentsFunctionName = "addNewAppointments";
exports.DeleteAppointmentsFunctionName = "deleteAppointments";
exports.GetCoordinatorAppointmentsFunctionName = "getCoordinatorAppointments";
exports.GetDonorsFunctionName = "getDonors";
exports.GetBookedDonationsInHospitalFunctionName = "getBookedDonationsInHospital";
