"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBloodTypeSelectOptions = void 0;
const types_1 = require("./types");
const index_1 = require("./index");
function getBloodTypeSelectOptions() {
    return [
        types_1.BloodType.A_MINUS,
        types_1.BloodType.A_PLUS,
        types_1.BloodType.B_MINUS,
        types_1.BloodType.B_PLUS,
        types_1.BloodType.AB_MINUS,
        types_1.BloodType.AB_PLUS,
        types_1.BloodType.O_MINUS,
        types_1.BloodType.O_PLUS,
        types_1.BloodType.NOT_SURE,
    ].map((type, index) => {
        return {
            key: index + type,
            value: type,
            label: index_1.LocaleUtils.getBloodTypeTranslation(types_1.BloodType[type]),
        };
    });
}
exports.getBloodTypeSelectOptions = getBloodTypeSelectOptions;
