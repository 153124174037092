"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputType = exports.AnalyticsEventType = exports.AnalyticsButtonType = exports.MANUAL_DONOR_ID = exports.AppointmentStatus = exports.BookingChange = exports.LoginStatus = exports.Collections = exports.Hospital = exports.CoordinatorRole = exports.BloodType = void 0;
var BloodType;
(function (BloodType) {
    BloodType["O_PLUS"] = "O_PLUS";
    BloodType["O_MINUS"] = "O_MINUS";
    BloodType["A_PLUS"] = "A_PLUS";
    BloodType["A_MINUS"] = "A_MINUS";
    BloodType["B_PLUS"] = "B_PLUS";
    BloodType["B_MINUS"] = "B_MINUS";
    BloodType["AB_PLUS"] = "AB_PLUS";
    BloodType["AB_MINUS"] = "AB_MINUS";
    BloodType["NOT_SURE"] = "NOT_SURE";
})(BloodType = exports.BloodType || (exports.BloodType = {}));
//https://docs.google.com/document/d/1Y3ovMRJhdHlJEd4rS3FCgxxY3qdpmk2WLaR45nL8IT8
var CoordinatorRole;
(function (CoordinatorRole) {
    CoordinatorRole["SYSTEM_USER"] = "SYSTEM_USER";
    CoordinatorRole["HOSPITAL_COORDINATOR"] = "HOSPITAL_COORDINATOR";
    CoordinatorRole["ADVOCATE"] = "ADVOCATE";
})(CoordinatorRole = exports.CoordinatorRole || (exports.CoordinatorRole = {}));
var Hospital;
(function (Hospital) {
    Hospital["TEL_HASHOMER"] = "TEL_HASHOMER";
    Hospital["ASAF_HAROFE"] = "ASAF_HAROFE";
    Hospital["BEILINSON"] = "BEILINSON";
    Hospital["HADASA_EIN_KEREM"] = "HADASA_EIN_KEREM";
    Hospital["ICHILOV"] = "ICHILOV";
    Hospital["RAMBAM"] = "RAMBAM";
    Hospital["SOROKA"] = "SOROKA";
})(Hospital = exports.Hospital || (exports.Hospital = {}));
var Collections;
(function (Collections) {
    Collections["COORDINATORS"] = "coordinators";
    Collections["DONORS"] = "donors";
    Collections["APPOINTMENTS"] = "appointments";
    Collections["GROUPS"] = "groups";
    Collections["UPDATES"] = "updates";
})(Collections = exports.Collections || (exports.Collections = {}));
var LoginStatus;
(function (LoginStatus) {
    LoginStatus[LoginStatus["UNKNOWN"] = 0] = "UNKNOWN";
    LoginStatus[LoginStatus["LOGGED_IN"] = 1] = "LOGGED_IN";
    LoginStatus[LoginStatus["LOGGED_OUT"] = 2] = "LOGGED_OUT";
})(LoginStatus = exports.LoginStatus || (exports.LoginStatus = {}));
var BookingChange;
(function (BookingChange) {
    BookingChange["BOOKED"] = "BOOKED";
    BookingChange["CANCELLED"] = "CANCELLED";
    BookingChange["COMPLETED"] = "COMPLETED";
    BookingChange["NOSHOW"] = "NOSHOW";
})(BookingChange = exports.BookingChange || (exports.BookingChange = {}));
var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["AVAILABLE"] = "AVAILABLE";
    AppointmentStatus["BOOKED"] = "BOOKED";
    AppointmentStatus["CONFIRMED"] = "CONFIRMED";
    AppointmentStatus["COMPLETED"] = "COMPLETED";
    AppointmentStatus["NOSHOW"] = "NOSHOW";
})(AppointmentStatus = exports.AppointmentStatus || (exports.AppointmentStatus = {}));
exports.MANUAL_DONOR_ID = "manual";
var AnalyticsButtonType;
(function (AnalyticsButtonType) {
    AnalyticsButtonType["ListItem"] = "list_item";
    AnalyticsButtonType["IconButton"] = "icon_button";
    AnalyticsButtonType["Button"] = "button";
    AnalyticsButtonType["TextButton"] = "text_button";
    AnalyticsButtonType["AnchorTag"] = "anchor_tag";
    AnalyticsButtonType["Picker"] = "picker";
    AnalyticsButtonType["Question"] = "question";
    AnalyticsButtonType["Wizard"] = "wizard";
    AnalyticsButtonType["Popup"] = "popup";
    AnalyticsButtonType["Checkbox"] = "checkbox";
    AnalyticsButtonType["Input"] = "input";
    AnalyticsButtonType["Radio"] = "radio";
    AnalyticsButtonType["Select"] = "select";
    AnalyticsButtonType["Toggle"] = "toggle";
})(AnalyticsButtonType = exports.AnalyticsButtonType || (exports.AnalyticsButtonType = {}));
var AnalyticsEventType;
(function (AnalyticsEventType) {
    AnalyticsEventType["Click"] = "click";
    AnalyticsEventType["PopupChange"] = "popup_change";
    AnalyticsEventType["ApiConfirmation"] = "api_confirmation";
})(AnalyticsEventType = exports.AnalyticsEventType || (exports.AnalyticsEventType = {}));
var InputType;
(function (InputType) {
    InputType["Email"] = "email";
    InputType["Password"] = "password";
    InputType["Text"] = "text";
    InputType["Phone"] = "phone";
})(InputType = exports.InputType || (exports.InputType = {}));
