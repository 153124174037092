"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMobileOperatingSystem = void 0;
function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
        return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "ios";
    }
    return "unknown";
}
exports.getMobileOperatingSystem = getMobileOperatingSystem;
